@import '@jnj/smn-common-styles-web/styles';

.support-request-modal {
  width: 684px;
  max-width: 684px;

  @include before($mdMinWidth) {
    width: 343px;
    max-width: 343px;
  }

  &__header {
    @include font-style($desktop-header1-black);
    margin: 0;
    align-self: center;

    @include before($mdMinWidth) {
      @include font-style($desktop-header2-black);
    }
  }

  &__body {
    @include font-style($desktop-body-grey-4);
    margin: 8px 0 32px 0;

    @include before($mdMinWidth) {
      margin-bottom: 24px;
    }
  }

  &__field {
    margin-bottom: 24px;

    @include before($mdMinWidth) {
      margin-bottom: 16px;
    }
  }

  &__buttons-bloc {
    display: flex;
    padding-top: 32px;
    flex-direction: row-reverse;

    @include before($mdMinWidth) {
      flex-direction: column;
      padding-top: 24px;
    }
  }

  &__button {
    width: 124px;

    &:first-child {
      width: 132px;
      margin-left: 24px;
    }

    @include before($mdMinWidth) {
      width: 100%;

      &:first-child {
        width: 100%;
        margin-bottom: 16px;
        margin-left: 0;
      }
    }
  }
}