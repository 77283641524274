@import '@jnj/smn-common-styles-web/styles';

.mobile-routing-navigation {
  border-bottom: 1px solid $gray-2;
  border-top: 1px solid $gray-2;

  &__button {
    margin-top: 16px;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  &__button-icon {
    position: absolute;
    left: 8px;
  }

  &__button-text {
    position: absolute;
    left: 38px;

    &--active {
      color: $red;
    }

    &--non-active {
      color: $gray-4;
    }
  }

  &__button-label {
    position: absolute;
    right: 12px;
    width: 39px;
    height: 24px;
    border: 1px solid $gray-2;
    border-radius: 12px;
    @include font-style($desktop-caption-red-semi-bold);
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button-label-text {
    margin: 0;
  }
}
