@import '@jnj/smn-common-styles-web/styles';

.error-page {
  background-color: $white;

  &__title {
    margin: 15vh 0 5vh;
    @include font-style($desktop-header1-red);
  }

  &__messages-container {
    margin: 0 0 10vh;
    text-align: center;
    @include font-style($desktop-body-black);
  }

  &__message {
    margin: 0;
  }

  &__button {
    min-width: 317px;
    margin-bottom: 5vh;
  }
}
