html {
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  text-size-adjust: 100%;
}

body {
  height: 100%;
}

img {
  border: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-variation-settings: 'opsz' 100; // https://clagnut.com/blog/2423 Font optical sizing bug in Safari 16
}

svg {
  pointer-events: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.grecaptcha-badge {
  transform: scale(0.85);
}
